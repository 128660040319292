import './styles.scss';
import { Button, Icon, Input, InputSize, MagnifyingGlassIcon } from '@kavetech/ds-core';
import { useForm } from 'react-hook-form';
import { IconSize } from '@kavetech/ds-core/dist/Components/Icon';

interface SearchBarProps {
  onSearch: (data: any) => void;
}

function SearchBar({ onSearch }: SearchBarProps): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
  });

  return (
    <form className="search-bar" onSubmit={handleSubmit(onSearch)}>
      <Input
        size={InputSize.MD}
        id="search-bar"
        type="text"
        {...register('searchValue', {
          required: 'Ce champ est requis',
        })}
        error={!!errors.searchValue}
        helpText={`${errors.searchValue?.message || ''}`}
        placeholder={'Entrez le SKU ou le titre du produit'}
        tabIndex={0}
      />
      <Button
        type="submit"
        icon={
          <Icon color="#ffffff" iconSize={IconSize.SM}>
            <MagnifyingGlassIcon />
          </Icon>
        }
        startIcon
      >
        Chercher
      </Button>
    </form>
  );
}
export default SearchBar;

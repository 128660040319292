import { PRODUCT_FEED_URLS } from './constants';
import { Product } from '../interfaces/product';

export const getPrintList = (): string[] => {
  return [...new Set(JSON.parse(localStorage.getItem('productsToPrint') || '[]'))] as unknown as string[];
};

const setPrintList = (productsToPrint: string[]) => {
  localStorage.setItem('productsToPrint', JSON.stringify([...new Set(productsToPrint)]));
};

export const addToPrintList = (id: string) => {
  setPrintList([id, ...getPrintList()]);
};

export const removeFromPrintList = (id: string) => {
  const productsToPrint = getPrintList();
  const index = productsToPrint.indexOf(id);
  if (index > -1) {
    productsToPrint.splice(index, 1);
    setPrintList(productsToPrint);
  }
};

export const getProductsOfFeeds = async () => {
  const urls = Object.values(PRODUCT_FEED_URLS);
  let haveErrors = false;
  const products = [];

  try {
    const responses = await Promise.all(urls.map((url) => fetch(url)));
    for (const response of responses) {
      if (response.ok) {
        const data = await response.json();
        products.push(...data);
      } else {
        haveErrors = true;
        console.error('Error al obtener el feed de productos:', response.url, response.status);
      }
    }
  } catch (error) {
    haveErrors = true;
    console.error('Error al obtener los feeds de productos:', error);
  }
  return { haveErrors, products };
};

export const filterProductsByIds = (products: Product[], ids: string[]): Product[] => {
  const productMap = new Map(products.map((product) => [product.id, product]));
  return ids.map((id) => productMap.get(id) as Product);
};

export const divideArray = <T>(array: T[], maxItems: number): T[][] => {
  const clonedArray = [...array];
  const dividedArray: T[][] = [];
  while (clonedArray.length) {
    dividedArray.push(clonedArray.splice(0, maxItems));
  }
  return dividedArray;
};

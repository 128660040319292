import './styles.scss';
import { Button, LoadingSpinner } from '@kavetech/ds-core';
import React, { useEffect, useState } from 'react';
import { Product } from '../../interfaces/product';
import { filterProductsByIds, getPrintList, getProductsOfFeeds } from '../../utils/functions';
import LabelList from '../../components/LabelList';

function PrintPreviewPage(): JSX.Element {
  const [printListIds, setPrintListIds] = useState<string[]>();
  const [printProducts, setPrintProducts] = useState<Product[]>();
  const [productsFeed, setProductsFeed] = useState<Product[]>();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getProductsOfFeeds().then(({ haveErrors, products }) => {
      if (haveErrors) {
        setErrorMessage(
          "Erreur lors de la tentative d'obtention de tous les produits. Veuillez réessayer ultérieurement."
        );
      } else {
        setProductsFeed(products);
      }
    });
    setPrintListIds(getPrintList());
  }, []);

  useEffect(() => {
    if (!printListIds || !productsFeed) return;
    setPrintProducts(filterProductsByIds(productsFeed, printListIds));
  }, [printListIds, productsFeed]);

  return (
    <div className="print-preview">
      <h1 className="print-preview__title">Prévisualisation des étiquettes</h1>
      {!productsFeed && !errorMessage && <LoadingSpinner />}
      {errorMessage && <div className="print-preview__error">{errorMessage}</div>}
      {productsFeed && !printProducts?.length && (
        <div className="print-preview__empty">
          Il n'y a pas de produits dans la liste à imprimer. Si vous souhaitez ajouter des produits, veuillez accéder à
          ce <a href="/">lien</a>.
        </div>
      )}
      {!!printProducts && !!printProducts.length && (
        <>
          <LabelList products={printProducts} />
          <div className="print">
            <Button handleClick={() => window.print()}>Imprimer</Button>
          </div>
        </>
      )}
    </div>
  );
}
export default PrintPreviewPage;

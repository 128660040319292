import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Header from './components/Header';
import { Route } from 'wouter';
import SearchPage from './pages/Search';
import Footer from './components/Footer';
import PrintListPage from './pages/PrintList';
import PrintPreviewPage from './pages/PrintPreview';
import SecureRoutes from './components/SecureRoutes';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <SecureRoutes>
      <div className="app">
        <Header />
        <div className="content">
          <Route path="/print-preview">
            <PrintPreviewPage />
          </Route>
          <Route path="/print-list">
            <PrintListPage />
          </Route>
          <Route path="/">
            <SearchPage />
          </Route>
        </div>
        <Footer />
      </div>
    </SecureRoutes>
  </React.StrictMode>
);

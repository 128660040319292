import './styles.scss';
import {
  Button,
  LoadingSpinner,
  Snackbar,
  SnackbarStatus,
  SnackbarType,
  XPosition,
  YPosition,
} from '@kavetech/ds-core';
import React, { useEffect, useState } from 'react';
import { Product } from '../../interfaces/product';
import {
  addToPrintList,
  removeFromPrintList,
  getPrintList,
  getProductsOfFeeds,
  filterProductsByIds,
} from '../../utils/functions';
import ProductItem from '../../components/ProductItem';

function PrintListPage(): JSX.Element {
  const [snackbarMessage, setSnackbarMessage] = useState<{ title: string; message: string } | null>(null);
  const [printListIds, setPrintListIds] = useState<string[]>();
  const [printProducts, setPrintProducts] = useState<Product[]>();
  const [productsFeed, setProductsFeed] = useState<Product[]>();
  const [errorMessage, setErrorMessage] = useState('');

  const handleAddToPrintList = ({ id, title }: Product) => {
    setSnackbarMessage({ title: "Produit ajouté à la liste d'impression", message: `${id} - ${title}` });
    addToPrintList(id);
    setPrintListIds(getPrintList());
  };

  const handleRemoveFromPrintList = ({ id, title }: Product) => {
    setSnackbarMessage({ title: "Produit supprimé de la liste d'impression", message: `${id} - ${title}` });
    removeFromPrintList(id);
    setPrintListIds(getPrintList());
  };

  useEffect(() => {
    getProductsOfFeeds().then(({ haveErrors, products }) => {
      if (haveErrors) {
        setErrorMessage(
          "Erreur lors de la tentative d'obtention de tous les produits. Veuillez réessayer ultérieurement."
        );
      } else {
        setProductsFeed(products);
      }
    });
    setPrintListIds(getPrintList());
  }, []);

  useEffect(() => {
    if (!printListIds || !productsFeed) return;
    setPrintProducts(filterProductsByIds(productsFeed, printListIds));
  }, [printListIds, productsFeed]);

  return (
    <div className="print-list">
      <h1 className="print-list__title">Liste des produits à imprimer</h1>
      {!!printProducts?.length && (
        <span className="print-list__subtitle">
          Vous manquez des produits dans la liste d'impression ? Ajoutez-les via <a href="/">le moteur de recherche</a>.
        </span>
      )}
      {!productsFeed && !errorMessage && <LoadingSpinner />}
      {errorMessage && <div className="print-list__error">{errorMessage}</div>}
      {productsFeed && !printProducts?.length && (
        <div className="print-list__empty">
          Il n'y a pas de produits dans la liste à imprimer. Si vous souhaitez ajouter des produits, veuillez accéder à
          ce <a href="/">lien</a>.
        </div>
      )}
      {!!printProducts && (
        <>
          <div className="print-list__list">
            {printProducts.map((product) => (
              <ProductItem
                key={product.id}
                product={product}
                addToPrintList={handleAddToPrintList}
                removeFromPrintList={handleRemoveFromPrintList}
              />
            ))}
          </div>
          <Snackbar
            animationDuration={500}
            duration={1500}
            isCloseable
            description={snackbarMessage?.message}
            onClose={() => setSnackbarMessage(null)}
            open={!!snackbarMessage}
            status={SnackbarStatus.INFO}
            title={snackbarMessage?.title}
            type={SnackbarType.FLOATING}
            xPosition={XPosition.CENTER}
            yPosition={YPosition.TOP}
          />

          <a href="/print-preview">
            <Button>Aperçu avant impression</Button>
          </a>
        </>
      )}
    </div>
  );
}
export default PrintListPage;

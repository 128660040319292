import './styles.scss';
import { Product } from '../../interfaces/product';
import ProductItem from '../ProductItem';

interface ProductListProps {
  products: Product[];
  addToPrintList: (product: Product) => void;
  removeFromPrintList: (product: Product) => void;
}
function ProductList({ products, addToPrintList, removeFromPrintList }: ProductListProps): JSX.Element {
  return (
    <div className="product-list">
      {products.map((product) => (
        <ProductItem
          key={product.id}
          product={product}
          addToPrintList={addToPrintList}
          removeFromPrintList={removeFromPrintList}
        />
      ))}
    </div>
  );
}
export default ProductList;

import './styles.scss';

const currentYear = new Date().getFullYear();

function Footer(): JSX.Element {
  return (
    <div className="footer">
      <span className="footer__copyright">© Copyright {currentYear} Kave Home, S.L.U. - kavehome.com</span>
    </div>
  );
}
export default Footer;

import './styles.scss';
import { Product } from '../../interfaces/product';
import { Colors, TrashIcon, Icon, PlusIcon } from '@kavetech/ds-core';
import { useState } from 'react';
import { getPrintList } from '../../utils/functions';

interface ProductItemProps {
  product: Product;
  addToPrintList: (product: Product) => void;
  removeFromPrintList: (product: Product) => void;
}

function ProductItem({ product, addToPrintList, removeFromPrintList }: ProductItemProps): JSX.Element {
  const [isSelected, setIsSelected] = useState(getPrintList().includes(product.id));
  const { id, category, title } = product;

  const handleButtonClick = () => {
    if (!isSelected) {
      addToPrintList(product);
    } else {
      removeFromPrintList(product);
    }
    setIsSelected(!isSelected);
  };

  return (
    <div className="item">
      <div className="item__info">
        <span className="category">{category}</span>
        <span className="title">{title}</span>
        <span className="sku">{id}</span>
      </div>
      <div className="item__action">
        <button onClick={handleButtonClick}>
          {!isSelected && (
            <Icon color={Colors.Neutral100}>
              <PlusIcon />
            </Icon>
          )}
          {isSelected && (
            <Icon color={Colors.RedDark}>
              <TrashIcon />
            </Icon>
          )}
        </button>
      </div>
    </div>
  );
}
export default ProductItem;

export interface Constants {
  [key: string]: string;
}

const BASE_URLS: Constants = {
  RETAIL_LABELS_API: 'https://client-api-dealer.kavehome.com/retail-labels/',
  PROXY_PASS: 'https://cors-anywhere.fritravich.com/',
};

export const PRODUCT_FEED_URLS = {
  SOFAS: `${BASE_URLS.RETAIL_LABELS_API}sofas`,
  FURNITURE: `${BASE_URLS.RETAIL_LABELS_API}furniture`,
  TABLES: `${BASE_URLS.RETAIL_LABELS_API}tables`,
  BEDS: `${BASE_URLS.RETAIL_LABELS_API}beds`,
  CHAIRS: `${BASE_URLS.RETAIL_LABELS_API}chairs`,
};

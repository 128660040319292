import './styles.scss';
import './print.scss';
import { Product } from '../../interfaces/product';
import { divideArray } from '../../utils/functions';
import ProductLabelTemplate from '../../templates';
import { useEffect, useState } from 'react';
interface LabelListProps {
  products: Product[];
}

function LabelList({ products }: LabelListProps): JSX.Element {
  const [dividedArray, setDividedArray] = useState<Product[][]>([]);

  useEffect(() => {
    setDividedArray(divideArray(products, 4));
  }, [products]);

  return (
    <>
      {dividedArray.map((nestedArray, index) => (
        <div className="label-list" key={index}>
          {nestedArray.map((product) => (
            <ProductLabelTemplate key={product.id} product={product} />
          ))}
        </div>
      ))}
    </>
  );
}
export default LabelList;

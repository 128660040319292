import './styles.scss';
import KhLogo from '../Icons/Logo';

function Header(): JSX.Element {
  const productsToPrint = JSON.parse(localStorage.getItem('productsToPrint') || '[]');
  return (
    <div className="header">
      <div className="header__logo">
        <a href="/">
          <KhLogo color="#FFFFFF" />
        </a>
      </div>
      <a className="header__print" href="/print-list">
        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.5 18V15H16.5V18M8.5 18V21H16.5V18M8.5 18H4.5V9H8.5M16.5 18H20.5V9H16.5M16.5 9H8.5M16.5 9V4C16.5 3.44772 16.0523 3 15.5 3H9.5C8.94772 3 8.5 3.44772 8.5 4V9"
            stroke="white"
            strokeLinecap="round"
          />
        </svg>
        {!!productsToPrint.length && <div className="header__print__bullet" />}
      </a>
    </div>
  );
}
export default Header;

import './styles.scss';
import SearchBar from '../../components/SearchBar';
import SearchList from '../../components/SearchList';
import { useEffect, useState } from 'react';
import { Product } from '../../interfaces/product';
import { Snackbar, SnackbarStatus, SnackbarType, XPosition, YPosition } from '@kavetech/ds-core';
import { addToPrintList, getProductsOfFeeds, removeFromPrintList } from '../../utils/functions';

const filterProductsByString = (products: Product[], searchString: string): Product[] => {
  return products.filter(
    (product) =>
      product.id.toLowerCase().includes(searchString.toLowerCase()) ||
      product.title.toLowerCase().includes(searchString.toLowerCase())
  );
};

const searchTextHaveSearchCharacters = (searchText: string): boolean => {
    return SEARCH_CHARACTERS.some((searchCharacter) => searchText.includes(searchCharacter));
}

const getSearchCharacter = (searchText: string): string => {
    return SEARCH_CHARACTERS.find((searchCharacter) => searchText.includes(searchCharacter)) || '';
}

const SEARCH_CHARACTERS = [',', ';', '|']

function SearchPage(): JSX.Element {
  const [productsFeed, setProductsFeed] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>();
  const [errorMessage, setErrorMessage] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState<{ title: string; message: string } | null>(null);
  const [errorOnMultipleSearch, setErrorOnMultipleSearch] = useState(false);
  const [notFoundProducts, setNotFoundProducts] = useState<string[]>([]);

  const onSearch = ({ searchValue }: any) => {
      setErrorOnMultipleSearch(false);
      searchValue = searchValue.replace(/\s/g, '');
    if (!searchValue) return;
    if (!searchTextHaveSearchCharacters(searchValue)) {
      setFilteredProducts(filterProductsByString(productsFeed, searchValue));
      return;
    }
    // If searchValue have search characters add print list
    const searchCharacter = getSearchCharacter(searchValue);
    const allSearchProducts: string[] = searchValue.split(searchCharacter);
    if (!allSearchProducts.length) return;

    // Filter allSearchProducts to get only products available
    setNotFoundProducts([]);
    const _notFoundProducts: string[] = [];
    const allProductsToAdd: string[] = [];
    allSearchProducts.forEach((searchProduct) => {
        const product = productsFeed.find((product) => product.id === searchProduct);
        if (product) {
            allProductsToAdd.push(product.id);
        } else {
            _notFoundProducts.push(searchProduct);
        }
    });

    // If searchValue contains more items than products found, show error
    if (!!_notFoundProducts.length) {
        setErrorOnMultipleSearch(true);
        setNotFoundProducts(_notFoundProducts);
    }

    setSnackbarMessage({ title: "Produits ajouté à la liste d'impression", message: allProductsToAdd.join(', ') });
    allProductsToAdd.forEach((productId) => addToPrintList(productId));
  };

  useEffect(() => {
    getProductsOfFeeds().then(({ haveErrors, products }) => {
      if (haveErrors) {
        setErrorMessage(
          "Erreur lors de la tentative d'obtention de tous les produits. Veuillez réessayer ultérieurement."
        );
      } else {
        setProductsFeed(products);
      }
    });
  }, []);

  const handleAddToPrintList = ({ id, title }: Product) => {
    setSnackbarMessage({ title: "Produit ajouté à la liste d'impression", message: `${id} - ${title}` });
    addToPrintList(id);
  };

  const handleRemoveFromPrintList = ({ id, title }: Product) => {
    setSnackbarMessage({ title: "Produit supprimé de la liste d'impression", message: `${id} - ${title}` });
    removeFromPrintList(id);
  };

  return (
    <div className="search-page">
      <h1 className="search-page__title">Choisissez les produits dont vous avez besoin et générez.</h1>
      <span className="search-page__subtitle">
        Commencez à rechercher les produits dont vous souhaitez obtenir un modèle et sélectionnez-en autant que vous le
        souhaitez. Appuyez ensuite sur le bouton d'aperçu. Le modèle sera exporté pour chacun des produits que vous
        sélectionnez.
      </span>
      <span className="search-page__subtitle" style={{alignSelf: 'start'}}>
        Pour effectuer plusieurs recherches, vous pouvez utiliser les séparateurs suivants: <b>, ; |</b>
      </span>
      <span className="search-page__subtitle" style={{alignSelf: 'start'}}>
        Exemple: J2100006JJ05,J1600010JJ03,C838R33
      </span>
      <SearchBar onSearch={onSearch} />
      {errorMessage && <span className="search-page__error">{errorMessage}</span>}
      {filteredProducts && !filteredProducts.length && (
        <span className="search-page__no-results">
          Impossible de trouver des résultats avec cette recherche. Veuillez réessayer en utilisant le nom de collection
          sinon la référence du produit (SKU)
        </span>
      )}
    {errorOnMultipleSearch && !!notFoundProducts.length && (
        <span className="search-page__no-results">
            Nous n'avons pas réussi à trouver des références. Références non disponibles : {notFoundProducts.join(', ')}
        </span>
    )}
      {filteredProducts && !!filteredProducts.length && (
        <SearchList
          products={filteredProducts}
          addToPrintList={handleAddToPrintList}
          removeFromPrintList={handleRemoveFromPrintList}
        />
      )}
      <Snackbar
        animationDuration={500}
        duration={1500}
        isCloseable
        description={snackbarMessage?.message}
        onClose={() => setSnackbarMessage(null)}
        open={!!snackbarMessage}
        status={SnackbarStatus.INFO}
        title={snackbarMessage?.title}
        type={SnackbarType.FLOATING}
        xPosition={XPosition.CENTER}
        yPosition={YPosition.TOP}
      />
    </div>
  );
}
export default SearchPage;

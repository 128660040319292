import { Product } from '../../interfaces/product';

interface SofasTemplateProps {
  product: Product;
}

function SofasTemplate({ product }: SofasTemplateProps): JSX.Element {
  return (
    <>
      {(!!product.fr_legs_material || !!product.fr_legs_finishing || !!product.fr_feet_pad_type) && (
        <div className="group">
          {!!product.fr_legs_material && (
            <div className="singleline">
              <span className="title">{product.fr_legs_material_title}</span>
              <span className="description">{product.fr_legs_material}</span>
            </div>
          )}
          {!!product.fr_legs_finishing && (
            <div className="singleline">
              <span className="title">{product.fr_legs_finishing_title}</span>
              <span className="description">{product.fr_legs_finishing}</span>
            </div>
          )}
          {!!product.fr_feet_pad_type && (
            <div className="singleline">
              <span className="title">{product.fr_feet_pad_type_title}</span>
              <span className="description">{product.fr_feet_pad_type}</span>
            </div>
          )}
        </div>
      )}
      {(!!product.fr_fabric_composition || !!product.fr_mattress_cover_material) && (
        <div className="group">
          {!!product.fr_fabric_composition && (
            <div className="singleline">
              <span className="title">{product.fr_fabric_composition_title}</span>
              <span className="description">{product.fr_fabric_composition}</span>
            </div>
          )}
          {!!product.fr_mattress_cover_material && (
            <div className="singleline">
              <span className="title">{product.fr_mattress_cover_material_title}</span>
              <span className="description">{product.fr_mattress_cover_material}</span>
            </div>
          )}
        </div>
      )}
      {(!!product.seat_foam_density || !!product.fr_armrest_density_foam || !!product.mattress_density_foam) && (
        <div className="group">
          {!!product.seat_foam_density && (
            <div className="singleline">
              <span className="title">{product.fr_seat_foam_density_title}</span>
              <span className="description">{product.seat_foam_density}</span>
            </div>
          )}
          {!!product.fr_armrest_density_foam && (
            <div className="singleline">
              <span className="title">{product.fr_armrest_density_foam_title}</span>
              <span className="description">{product.fr_armrest_density_foam}</span>
            </div>
          )}
          {!!product.mattress_density_foam && (
            <div className="singleline">
              <span className="title">{product.fr_mattress_density_foam_title}</span>
              <span className="description">{product.mattress_density_foam}</span>
            </div>
          )}
        </div>
      )}
      {(!!product.fr_mattress_included || !!product.assembly_time || !!product.id) && (
        <div className="group">
          {!!product.fr_mattress_included && (
            <div className="singleline">
              <span className="title">{product.fr_mattress_included_title}</span>
              <span className="description">{product.fr_mattress_included}</span>
            </div>
          )}
          {!!product.assembly_time && (
            <div className="singleline">
              <span className="title">{product.fr_assembly_time_title}</span>
              <span className="description">{product.assembly_time}</span>
            </div>
          )}
          {!!product.id && (
            <div className="singleline">
              <span className="title">{product.fr_SKU_code_title}</span>
              <span className="description">{product.id}</span>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default SofasTemplate;

import React, { useEffect, useState } from 'react';
import { sha256 } from 'js-sha256';
import { LoadingSpinner } from '@kavetech/ds-core';

const REDIRECT_URL = 'https://templatebuilder.kavehome.com/';

const verifyHash = (hash: string): boolean => {
  const retailLabelsSeed = '08c8499a8639a873ef1856e9a97d8434';
  const computedHash = sha256(retailLabelsSeed);
  return computedHash === hash;
};

const redirectToTemplateBuilder = (): void => {
  window.location.href = REDIRECT_URL;
};

interface SecureRoutesProps {
  children: JSX.Element;
}

function SecureRoutes({ children }: SecureRoutesProps): JSX.Element {
  const hashParam = new URLSearchParams(window.location.search).get('hash');
  const storedHash = localStorage.getItem('hash');

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (!hashParam && !storedHash) redirectToTemplateBuilder();
    if (hashParam) {
      if (verifyHash(hashParam)) {
        setIsAuthenticated(true);
        localStorage.setItem('hash', hashParam);
      } else {
        redirectToTemplateBuilder();
      }
    }
    if (storedHash) {
      if (verifyHash(storedHash)) {
        setIsAuthenticated(true);
      } else {
        redirectToTemplateBuilder();
      }
    }
  }, [hashParam, storedHash]);

  if (isAuthenticated) return children;
  return <LoadingSpinner />;
}
export default SecureRoutes;

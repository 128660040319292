import './styles.scss';
import { Product } from '../../interfaces/product';
import ProductList from '../ProductList';

interface SearchListProps {
  products: Product[];
  addToPrintList: (product: Product) => void;
  removeFromPrintList: (product: Product) => void;
}
function SearchList({ products, addToPrintList, removeFromPrintList }: SearchListProps): JSX.Element {
  return (
    <div className="search-list">
      <h2 className="search-list__title">Vos résultats de recherche ({products.length})</h2>
      <ProductList {...{ products, addToPrintList, removeFromPrintList }} />
    </div>
  );
}
export default SearchList;

import { Product } from '../interfaces/product';
import { ProductTemplate } from '../enums/product-template';
import SofasTemplate from './Sofas';
import BedsTemplate from './Beds';
import FurnitureTemplate from './Furniture';
import TablesTemplate from './Tables';
import ChairsTemplate from './Chairs';
import KhLogo from '../components/Icons/Logo';

import './styles.scss';
import './print.scss';

interface ProductLabelTemplateProps {
  product: Product;
}

const getProductTemplate = (product: Product): JSX.Element => {
  switch (product.template_type) {
    case ProductTemplate.BEDS:
      return <BedsTemplate product={product} />;
    case ProductTemplate.TABLES:
      return <TablesTemplate product={product} />;
    case ProductTemplate.CHAIRS:
      return <ChairsTemplate product={product} />;
    case ProductTemplate.FURNITURE:
      return <FurnitureTemplate product={product} />;
    case ProductTemplate.SOFAS:
    default:
      return <SofasTemplate product={product} />;
  }
};

function ProductLabelTemplate({ product }: ProductLabelTemplateProps): JSX.Element {
  return (
    <div className="label">
      <div className="label__header">
        <div className="label__header__name">{product.collection}</div>
        <div className="label__header__description">{product.fr_product_description}</div>
      </div>
      <div className="label__content">
        <div className="multiline">
          <span className="title">{product.fr_dimensions_title}</span>
          <span className="description">{product.product_dimensions}</span>
        </div>
        <div className="multiline">
          <span className="title">{product.fr_materials_title}</span>
          <span className="description">{product.fr_materials}</span>
        </div>
        {getProductTemplate(product)}
      </div>
      <div className="label__footer">
        <div className="label__footer__shipping">
          <span>{product.fr_shipping_text}</span>
        </div>
        <div className="label__footer__prices">
          <span className="price">{product.fr_sale_price}</span>
          {!!product.fr_recommended_retail_price && (
            <span className="discount">{product.fr_recommended_retail_price}</span>
          )}
          {!!product.fr_ecopart_with_tax && <span className="ecopart">{product.fr_ecopart_with_tax}</span>}
        </div>
      </div>
      <div className="label__logo">
        <KhLogo />
      </div>
    </div>
  );
}

export default ProductLabelTemplate;

import { Product } from '../../interfaces/product';

interface TablesTemplateProps {
  product: Product;
}

function TablesTemplate({ product }: TablesTemplateProps): JSX.Element {
  return (
    <>
      {(!!product.fr_legs_material || !!product.fr_legs_finishing || !!product.fr_feet_pad_type) && (
        <div className="group">
          {!!product.fr_legs_material && (
            <div className="singleline">
              <span className="title">{product.fr_legs_material_title}</span>
              <span className="description">{product.fr_legs_material}</span>
            </div>
          )}
          {!!product.fr_legs_finishing && (
            <div className="singleline">
              <span className="title">{product.fr_legs_finishing_title}</span>
              <span className="description">{product.fr_legs_finishing}</span>
            </div>
          )}
          {!!product.fr_feet_pad_type && (
            <div className="singleline">
              <span className="title">{product.fr_feet_pad_type_title}</span>
              <span className="description">{product.fr_feet_pad_type}</span>
            </div>
          )}
        </div>
      )}

      {(!!product.assembly_time || !!product.id) && (
        <div className="group">
          {!!product.assembly_time && (
            <div className="singleline">
              <span className="title">{product.fr_assembly_time_title}</span>
              <span className="description">{product.assembly_time}</span>
            </div>
          )}
          {!!product.id && (
            <div className="singleline">
              <span className="title">{product.fr_SKU_code_title}</span>
              <span className="description">{product.id}</span>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default TablesTemplate;
